
import { DayOfWeek } from './DayOfWeek';
import { TimeSpan } from './TimeSpan';

export class WorkingDay {
  dayOfWeek: DayOfWeek | null = null;
  start = TimeSpan.zero;
  end = TimeSpan.zero;
  breakStart = TimeSpan.zero;
  breakEnd = TimeSpan.zero;
  evenWeek: boolean | null = null

  constructor(json?: any) {
    if (json != null) {
      this.dayOfWeek = json.dayOfWeek;
      this.start = TimeSpan.fromString(json.start);
      this.end = TimeSpan.fromString(json.end);
      this.breakStart = TimeSpan.fromString(json.breakStart);
      this.breakEnd = TimeSpan.fromString(json.breakEnd);
      this.evenWeek = json.evenWeek;
    }
  }

  cloneTimes(dayOfWeek: DayOfWeek): WorkingDay {
    const newDay = new WorkingDay();
    newDay.dayOfWeek = dayOfWeek;
    newDay.start = TimeSpan.fromMilliseconds(this.start.totalMilliseconds);
    newDay.end = TimeSpan.fromMilliseconds(this.end.totalMilliseconds);
    newDay.breakStart = TimeSpan.fromMilliseconds(this.breakStart.totalMilliseconds);
    newDay.breakEnd = TimeSpan.fromMilliseconds(this.breakEnd.totalMilliseconds);
    newDay.evenWeek = this.evenWeek;
    return newDay;
  }

  equals(secondWH: WorkingDay): boolean {
    return this.start.totalMilliseconds === secondWH.start.totalMilliseconds &&
      this.end.totalMilliseconds === secondWH.end.totalMilliseconds &&
      this.breakStart.totalMilliseconds === secondWH.breakStart.totalMilliseconds &&
      this.breakEnd.totalMilliseconds === secondWH.breakEnd.totalMilliseconds &&
      this.evenWeek === secondWH.evenWeek;
  }

}
