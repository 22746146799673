import { WorkingDay } from './WorkingDay';
export class Slot {
  slotID = 0;
  officeID: number = 0;
  name: string = '';
  useOfficeWorkingDays = true;
  deleted = false;
  workingDays: WorkingDay[] = [];
  hasSlotPlacement = false;
  betweenReservationsDuration: number = 0;
  isPublic = true;

  constructor() {
    for (let index = 0; index < 7; index++) {
      const day = new WorkingDay();
      day.dayOfWeek = index;
      this.workingDays.push(day);

    }
  }

  get sameTimeForWorkingDays() {
    const mondayWH = this.workingDays[1];

    for (let index = 2; index < 6; index++) {
      const wh = this.workingDays[index];
      if (!mondayWH.equals(wh)) {
        return false;
      }
    }
    return true;
  }

  static fromObject(obj: Slot): Slot {
    const newSlot = new Slot();
    Object.assign(newSlot, obj);

    const receivedWorkingDays: WorkingDay[] = obj.workingDays;
    if (receivedWorkingDays) {

      newSlot.workingDays = [];


      receivedWorkingDays.forEach((receivedDay) => {
        var wDay = new WorkingDay(receivedDay);

        newSlot.workingDays.push(wDay);
      });

    }
    return newSlot;
  }
}


export interface SlotPlacement {
  slotID: number;
  xPosition: number;
  yPosition: number;
  groupID: string;
  type: number;
}
