import { ToastrService } from 'ngx-toastr';

import { AuthServiceService as myAuth } from '../core/services/auth-service.service';
import { Component } from '@angular/core';
import { AuthService, SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LoginResult } from '../core/interfaces/User';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  isBusy = false;
  user: SocialUser;


  loginForm: FormGroup;

  constructor(private authService: AuthService, fb: FormBuilder,
    private myAuthService: myAuth, private router: Router, private toast: ToastrService) {

    this.loginForm = fb.group({
      'username': '',
      'password': ''
    });
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        // on success
        this.isBusy = true;
        // this will return user data from google. What you need is a user token which you will send it to the server
        this.myAuthService.googleSignInExternal(userData.idToken)
          // .pipe(finalize(() => this.isLoading = false))
          .subscribe(result => {
            this.loginCompleted(result);

          });
      });
  }

  signInWithFB(): void {

    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, { enable_profile_selector: true })
      .then((userData) => {
        this.isBusy = true;
        // on success
        // this will return user data from google. What you need is a user token which you will send it to the server
        this.myAuthService.facebookSignInExternal(userData.authToken)
          // .pipe(finalize(() => this.isLoading = false))
          .subscribe(result => {
            this.loginCompleted(result);

          });
      });
  }

  signOut(): void {
    this.authService.signOut();
  }


  login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.isBusy = true;

    this.myAuthService.login(this.loginForm.value).subscribe(
      result => {
        this.loginCompleted(result);
      }
    );
  }

  loginCompleted(result: LoginResult) {
    this.isBusy = false;
    switch (result) {
      case (LoginResult.OK):
        this.router.navigate(['/backoffice']);
        break;
      case (LoginResult.Disabled):
        this.toast.error('Nalog je zaključan');
        break;
      case (LoginResult.InvalidUserName):
        this.toast.error('Pogrešno korisničko ime i/ili šifra');
        break;
    }
  }

}
