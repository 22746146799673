import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { filter, take, timeout } from 'rxjs/operators';

const prefix = 'crossDomainRequestID-';

@Injectable({
  providedIn: 'root'
})
export class CrossDomainStorageService {
  _iFrame?: HTMLIFrameElement;
  _loadingFramePromise?: Promise<void>;

  private isLoaded = new BehaviorSubject<boolean>(false);

  private _callbacks: {
    [key: string]: {
      resolve: (value: any) => void,
      reject: (err: any) => void,
      timer: Subscription
    }
  } = {};
  private source = environment.webBase + "/storage";

  constructor() {
    window.addEventListener('message', this.handleMessage.bind(this));
  }

  loadFrame(): Promise<void> {
    if (this._loadingFramePromise != null) {
      return this._loadingFramePromise;
    }

    this._loadingFramePromise = new Promise((resolve, reject) => {
      if (this.isLoaded.value) {
        return resolve();
      }

      this._iFrame = document.createElement("iframe");
      this._iFrame.src = this.source;
      this._iFrame.style.display = 'none';
      this._iFrame.addEventListener("load", () => {
        this.isLoaded.next(true)
        return resolve();
      });

      document.body.append(this._iFrame);


    })

    return this._loadingFramePromise;
  }

  handleMessage(event: any) {
    const response = event.data;
    const requestID = this.getId(response);

    if (event.origin !== environment.webBase) {
      return;
    }

    if (!requestID) {
      return;
    }

    var promise = this._callbacks[requestID];
    promise.timer.unsubscribe();

    if (!!promise) {
      if (response.connectError) {
        promise.reject(response.error);
      } else {
        promise.resolve(response.data);
      }
    }
  }

  private createID() {
    return prefix + "-" + new Date().getTime();
  }

  get(key: string) {
    var promiseResolve!: (value: any) => void;
    var promiseReject!: (err: any) => void;

    var promise = new Promise<any>((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    this.message('get', key, null, promiseResolve, promiseReject);

    return promise;
  }


  set(key: string, value: any) {

    var promiseResolve!: (value: any) => void;
    var promiseReject!: (err: any) => void;

    var promise = new Promise<any>((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    this.message('set', key, value, promiseResolve, promiseReject);

    return promise;
  }

  remove(key: string) {
    var promiseResolve!: (value: any) => void;
    var promiseReject!: (err: any) => void;

    var promise = new Promise<any>((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });

    this.message('remove', key, null, promiseResolve, promiseReject);

    return promise;
  }

  message(method: string, key: string, value: any, resolve: (value: unknown) => void, reject: (err: any) => void) {
    this.loadFrame().then(() => {

      const id = this.createID();

      this._callbacks[id] = {
        resolve: resolve,
        reject: reject,
        timer: timer(2000).subscribe(() => reject("timeout"))
      };

      this.isLoaded.pipe(
        filter(val => val),
        timeout(5000),
        take(1)
      ).subscribe(
        () => {
          if (this.isLoaded) {
            this._iFrame!.contentWindow?.postMessage(
              {
                method,
                key,
                value,
                id,
              },
              this.source,
            );
          } else {
            reject('timeout');
          }
        },
        () => {
          reject("storage not loaded");
        });
    });
  }

  getId(data: any) {
    let id;

    if (data && data.id && ~data.id.indexOf(prefix)) {
      id = data.id;
    }

    return id;
  }


}
