import { ReservationStatus } from 'src/app/core/interfaces/Reservation';
export interface IUser {
  completedReservations: number;
  noShowReservations: number;
  totalReservations: number;

  eMail: string;
  firstName: string;
  lastName: string;
  name: string;
  note: string;
  phoneNo: string;
  photoUrl: string;
  rating: number;
  registered: Date | null;
  userID: number;
  birthday: Date | null;
  address: string | null;
}

export class User implements IUser {
  userID = 0;
  firstName = '';
  lastName = '';
  eMail = '';
  phoneNo = '';
  isAdmin = false;
  token: string = '';
  completedReservations: number = 0;
  totalReservations: number = 0;
  noShowReservations: number = 0;
  rating: number = 0;
  isPhoneVerified: boolean = false;
  photoUrl = '';
  disabled: boolean = false;
  note = '';
  lastLogin?: Date;
  registered = null;
  birthday = null;
  tosAccepted = false;
  address = '';

  get name() {

    if (!this.firstName) {
      return this.lastName;
    }
    return this.firstName + ' ' + this.lastName;
  }
}

export class RegisterUserModel {
  eMail = '';
  password = '';
  firstName = '';
  lastName = '';
  phoneNo = '';
  tosAccepted = false;
}

export enum LoginResult {
  InvalidUserName,
  Disabled,
  OK
}

export class CompanyUser implements IUser {
  totalReservations = 0;
  completedReservations: number = 0;
  noShowReservations: number = 0;
  lastReservationStatus: ReservationStatus = ReservationStatus.Active;

  companyID = 0;
  userID = 0;
  firstName = '';
  lastName = '';
  phoneNo = '';
  eMail = '';
  note = '';
  disableNotifications = false;
  photoUrl = '';
  registered: Date | null = null;
  rating: number = 0;
  birthday: Date | null = null;
  address = '';

  get name() {

    if (!this.firstName) {
      return this.lastName;
    }
    return this.firstName + ' ' + this.lastName;
  }

  get normalizedName() {
    return this.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  get isEmpty(): boolean {
    return !(this.name || this.phoneNo);
  }

  static fromJSON(u: CompanyUser): CompanyUser {
    const user = new CompanyUser();
    Object.assign(user, u);
    return user;
  }

}

export interface UserSettings {
  notificationPlaySound?: boolean;
  notificationAutoClose?: boolean;
  reservationsShowCanceled?: boolean;
  apiKey?: string;
  debug?: boolean;
  hideSlotsWithoutTimeSlot?: boolean;
}

export enum EmployeeType {
  Regular = 1,
  Partner = 2
}

export class Employee {
  user = new User();
  type = EmployeeType.Regular;
  companyID: number = 0;

  static fromJson(u: Employee) {
    const e = new Employee();
    Object.assign(e, u);

    e.user = new User();
    Object.assign(e.user, u.user);

    return e;
  }

}
