import { FilterReservationsBySlotIDPipe } from './pipes/filter-reservations-by-slot-id.pipe';
import { AvailableReservationDateGroupperPipe } from './pipes/AvailableReservationDateGroupper.pipe';
import { ToAvatarURLPipe } from './pipes/to-avatar-url.pipe';
import { VisibleWith } from './VisibleWith.directive';
import { ReservationListComponent } from './reservation-list/reservation-list.component';
import { ReservationStatusNamePipe } from './pipes/ReservationStatusName.pipe';
import { ReservationStatusComponent } from './reservation-status/reservation-status.component';
import { CoreModule } from './../core/core.module';
import { LocationFromIDPipe } from './pipes/LocationFromID.pipe';
import { TopLevelLocationsPipe } from './pipes/TopLevelLocations';
import { SortPipe } from './pipes/Sort.pipe';
import { MinutesToStringPipe } from './pipes/MinutesToString.pipe';
import { IsPassedPipe } from './pipes/IsPassed.pipe';
import { IsTimeSlotAvailablePipe } from './pipes/IsTimeSlotAvailablePipe';
import { ToTimeStringPipe } from './pipes/ToTimeStringPipe';
import { LocationsSortPipe } from './pipes/LocationsSort.pipe';
import { LocationNamePipe } from './pipes/LocationName.pipe';

import { WorkingDaysEditComponent } from './working-days-edit/working-days-edit.component';
import { AddonTypeToNamePipe } from './pipes/AddonTypeToName.pipe';
import { CalendarViewToStringPipe } from './pipes/CalendarViewToString.pipe';
import { SelectAllOnFocusDirective } from './helpers/SelectAllOnFocus.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { OnlyNumberDirective } from './helpers/OnlyNumber.directive';
import { ApiResponseInterceptor } from './helpers/ApiResponseInterceptor';
import { HttpErrorInterceptor } from './helpers/HttpErrorInterceptor';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClickStopPropagationDirective } from './helpers/ClickStopPropagation.directive';
import { PhoneVerificationCodeComponent } from './phone-verification-code/phone-verification-code.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';


import { PaginationComponent } from './pagination/pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MyNgxIntlTelInputModule } from '../MyNgxIntlTelInput/ngx-intl-tel-input.module';
import { SlotDurationToStringPipe } from './slot-duration-to-string.pipe';
import { PriceToStringPipe } from './price-to-string.pipe';
import { BundleLimitPipe } from './bundle-limit.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { ToProductNamesPipe } from '../shared/pipes/ToProductNamesPipe';
import { ToSlotNamesPipe } from '../shared/pipes/ToSlotNames.pipe';
import { CalendarComponent } from './calendar/calendar.component';
import { ContactComponent } from './contact/contact.component';
import { IsBusyDirective } from './directives/is-busy.directive';
import { TimeSelectorComponent } from './time-selector/time-selector.component';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OneLineCalendarComponent } from './one-line-calendar/one-line-calendar.component';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { ToBundleNamePipe } from './pipes/ToBundleName.pipe';
import { ToDispatcherDescriptionPipe } from './pipes/ToDispatcherDescription.pipe';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { CompanyTypeNamePipe } from './pipes/CompanyTypeName.pipe';
import { TruncateHtmlPipe } from './pipes/TruncateHtmlPipe';
import { PhoneNumberFormatterPipe } from './pipes/PhoneNumberFormatter.pipe';
import { VersionCheckComponent } from './version-check/version-check.component';
import { SlotClassNamePipe } from './pipes/SlotClassName.pipe';
import { FilterCompanyTypeByGroupIDPipe } from './pipes/filter-company-type-by-group-id.pipe';
import { AvailableReservationDistinctDaysPipe } from './pipes/available-reservation-distinct-days.pipe';
import { AvailableReservationDistinctOfficesForDatePipe } from './pipes/available-reservation-distinct-offices-for-date.pipe';
import { FilterReservationsByDatePipe } from './pipes/filter-reservations-by-date.pipe';
import { MatContextMenuTrigger } from './mat-context-menu-trigger/mat-context-menu-trigger.component';
import { FilterCanceledReservationsPipe } from './pipes/FilterCanceledReservations.pipe';
import { SortByDatePipe } from './pipes/sort-by-date.pipe';
import { ReservationStatusClassNamePipe } from './pipes/reservation-status-class-name.pipe';

@NgModule({
  declarations: [
    OnlyNumberDirective,
    UserAvatarComponent,
    UserEditComponent,
    ClickStopPropagationDirective,
    PhoneVerificationCodeComponent,
    PaginationComponent,
    SelectAllOnFocusDirective,
    CalendarViewToStringPipe,
    SlotDurationToStringPipe,
    PriceToStringPipe,
    BundleLimitPipe,
    ToProductNamesPipe,
    ToSlotNamesPipe,
    CalendarComponent,
    AddonTypeToNamePipe,
    WorkingDaysEditComponent,
    ContactComponent,
    IsBusyDirective,
    LocationNamePipe,
    LocationsSortPipe,
    TimeSelectorComponent,
    ToTimeStringPipe,
    IsTimeSlotAvailablePipe,
    AutoCompleteComponent,
    IsPassedPipe,
    MinutesToStringPipe,
    CheckoutComponent,
    OneLineCalendarComponent,
    SortPipe,
    PromptDialogComponent,
    TopLevelLocationsPipe,
    LocationFromIDPipe,
    InvoiceDetailsComponent,
    ReservationStatusComponent,
    ReservationStatusNamePipe,
    ToBundleNamePipe,
    ToDispatcherDescriptionPipe,
    LoadingSpinnerComponent,
    CompanyTypeNamePipe,
    ReservationListComponent,
    TruncateHtmlPipe,
    PhoneNumberFormatterPipe,
    VersionCheckComponent,
    VisibleWith,
    SlotClassNamePipe,
    FilterCompanyTypeByGroupIDPipe,
    ToAvatarURLPipe,
    AvailableReservationDateGroupperPipe,
    AvailableReservationDistinctDaysPipe,
    AvailableReservationDistinctOfficesForDatePipe,
    FilterReservationsByDatePipe,
    FilterReservationsBySlotIDPipe,
    MatContextMenuTrigger,
    FilterCanceledReservationsPipe,
    SortByDatePipe,
    ReservationStatusClassNamePipe
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ClickOutsideModule,
    NgxPaginationModule,
    MyNgxIntlTelInputModule,
    PopoverModule.forRoot(),
    AngularMaterialModule,
    NgxMaterialTimepickerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CoreModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  exports: [
    UserAvatarComponent,
    ClickStopPropagationDirective,
    PaginationComponent,
    ClickOutsideModule,
    SelectAllOnFocusDirective,
    CalendarViewToStringPipe,
    MyNgxIntlTelInputModule,
    SlotDurationToStringPipe,
    PriceToStringPipe,
    BundleLimitPipe,
    ToProductNamesPipe,
    ToSlotNamesPipe,
    CalendarComponent,
    AddonTypeToNamePipe,
    WorkingDaysEditComponent,
    NgxMaterialTimepickerModule,
    ContactComponent,
    IsBusyDirective,
    LocationNamePipe,
    LocationsSortPipe,
    TimeSelectorComponent,
    ToTimeStringPipe,
    IsTimeSlotAvailablePipe,
    AutoCompleteComponent,
    IsPassedPipe,
    MinutesToStringPipe,
    OneLineCalendarComponent,
    SortPipe,
    TopLevelLocationsPipe,
    LocationFromIDPipe,
    InvoiceDetailsComponent,
    ReservationStatusComponent,
    ToBundleNamePipe,
    ToDispatcherDescriptionPipe,
    LoadingSpinnerComponent,
    CompanyTypeNamePipe,
    ReservationListComponent,
    TruncateHtmlPipe,
    PhoneNumberFormatterPipe,
    VersionCheckComponent,
    VisibleWith,
    SlotClassNamePipe,
    FilterCompanyTypeByGroupIDPipe,
    ToAvatarURLPipe,
    AvailableReservationDateGroupperPipe,
    AvailableReservationDistinctDaysPipe,
    AvailableReservationDistinctOfficesForDatePipe,
    FilterReservationsByDatePipe,
    FilterReservationsBySlotIDPipe,
    MatContextMenuTrigger,
    FilterCanceledReservationsPipe,
    SortByDatePipe,
    ReservationStatusClassNamePipe
  ]
})
export class SharedModule { }
