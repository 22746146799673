
import { AuthServiceService } from './core/services/auth-service.service';
import { Component, OnInit } from '@angular/core';
import { AuthService, SocialUser } from 'angularx-social-login';
import { Router } from '@angular/router';


@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent implements OnInit {

  user: SocialUser;
  constructor(private authService: AuthService,
    private myAuhtService: AuthServiceService, private router: Router) {

  }

  ngOnInit(): void {
    this.myAuhtService.signOut();

    this.authService.signOut(true).catch();

    this.router.navigate(['/']);
  }

}
