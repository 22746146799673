import { Slot } from "../interfaces/Slot";
import { CompanyUser, User } from "../interfaces/User";
import { WorkingDay } from "../interfaces/WorkingDay";

export const toLocalDateJsonString = (date: Date | null) => {
  if (!date) {
    return null;
  }

  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + 'T' +
    ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
};

export const mapJsonToSlots = (receivedSlots: Slot[]): Slot[] => {
  const slots: Slot[] = [];
  receivedSlots.forEach(receivedSlot => {
    const newSlot = Slot.fromObject(receivedSlot);
    slots.push(newSlot);
  });
  return slots;
};

export const mapJsonToUsers = (jsonItems: User[]): User[] => {
  const list: User[] = [];
  jsonItems.forEach(u => {
    const newUser = new User();
    Object.assign(newUser, u);
    list.push(newUser);
  });
  return list;
};

export const mapJsonToCompanyUsers = (jsonItems: CompanyUser[]): CompanyUser[] => {
  const list: CompanyUser[] = [];
  jsonItems.forEach(u => {
    const newUser = new CompanyUser();
    Object.assign(newUser, u);
    if (u.birthday) {
      newUser.birthday = new Date(u.birthday);
    }
    list.push(newUser);
  });
  return list;
};
export const parseToLocalDate = (date: string | Date | null): Date | null => {
  if (!date) {
    return null;
  }
  // var s = '2011-06-21T14:27:28.593Z';
  /*
  const a = date.toString().split(/[^0-9]/).map(i => parseInt(i, 10));

  const d = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
  */
  return  new Date(date);
};


export const flattenWorkingDays = (days: WorkingDay[]) => {
  const data: any[] = [];
  if (!days) {
    return null;
  }

  days.forEach((element) => {
    data.push({
      dayOfWeek: element.dayOfWeek,
      start: element.start.value,
      end: element.end.value,
      breakStart: element.breakStart.value,
      breakEnd: element.breakEnd.value,
      evenWeek: element.evenWeek
    }
    );
  });

  return data;
};
