import { ErrorService } from './../../core/services/error.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  apiError = new BehaviorSubject(false);
  constructor(private errorService: ErrorService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        //  retry(3),
        catchError((error: HttpErrorResponse) => {
          if (error.status !== 404) {
            this.errorService.apiError = true;
          }
          return throwError(error);
        }),
        tap(() => {
          this.errorService.apiError = false;

        })
      );
  }
}
