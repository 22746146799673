import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-pwa',
  templateUrl: './reset-pwa.component.html',
  styleUrls: ['./reset-pwa.component.scss']
})
export class ResetPWAComponent implements OnInit {

  constructor(router: Router) {
    navigator.serviceWorker.getRegistrations().then(
      registrations => {
        registrations.forEach(r => {
          console.log('Reseting: ', r.scope)
          r.unregister()
        });

        router.navigate(['/'], { replaceUrl: true });
      }
    ).catch((err) => console.log('Reset failed: ', err))
  }

  ngOnInit(): void {
  }

}
