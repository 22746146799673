import { version } from './../../../environments/verision';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ErrorCodes } from 'src/app/core/interfaces/ErrorCodes';

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor(private toast: ToastrService, private router: Router) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessDeniedDescription: String;


    if (!req.url.startsWith(environment.webApiBase)) {
      return next.handle(req);
    }


    req = req.clone({
      headers: req.headers.set('X-App-Version', version.toString())
    });

    if (req.headers.get('SkipApiResponseInterceptor') != null) {
      const newHeaders = req.headers.delete('SkipApiResponseInterceptor');
      const newRequest = req.clone({ headers: newHeaders });
      return next.handle(newRequest);
    }

    if (req.headers.get('accessDeniedDescription') != null) {
      accessDeniedDescription = req.headers.get('accessDeniedDescription');

      const newHeaders = req.headers.delete('accessDeniedDescription');
      req = req.clone({ headers: newHeaders });
    }

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.success !== undefined && !evt.body.success && evt.body.errorCode) {
            let message: string;

            switch (evt.body.errorCode as ErrorCodes) {
              case (ErrorCodes.InvalidSlot):
                message = 'Neispravna pozicija';
                break;
              case (ErrorCodes.NotEnoughTime):
                message = 'Nema dovoljno vremena za završetak svih odabranih usluga';
                break;
              case (ErrorCodes.ProductNotFound):
                message = 'Usluga nije nađena';
                break;
              case (ErrorCodes.TimeNotValid):
                message = 'Neispravno vreme';
                break;
              case (ErrorCodes.TimeSlotNotFound):
                message = 'Odabrani termin je van radnog vremena';
                break;
              case (ErrorCodes.TooManySlots):
                message = 'Maksimalan broj pozicija dostignut';
                break;
              case (ErrorCodes.AccessDenied):
                message = 'Nemate prava ' + (accessDeniedDescription || 'za ovu akciju');
                break;
              case (ErrorCodes.InvalidOffice):
                message = 'Poslovnica nije nađena';
                break;
              case (ErrorCodes.InvalidFreeTimeBetweenReservations):
                message = 'Razmak od poslednje rezervacije nije ispravan';
                break;
              case (ErrorCodes.InvalidCompany):
                message = 'Kompanija nije nađena';
                break;
              case (ErrorCodes.TooManyOffices):
                message = 'Maksimalan broj poslovnica dostignut';
                break;
              case (ErrorCodes.TooManyReservations):
                message = 'Maksimalan broj rezervacija dostignut';
                break;
              case (ErrorCodes.SubscriptionExpired):
                message = 'Pretplata je istekla';
                break;
              case (ErrorCodes.InvalidOperation):
                message = 'Akcija nije moguća';
                break;
              case (ErrorCodes.PhoneNoNotVerified):
                message = 'Broj telefona nije verifikovan';
                break;
              case (ErrorCodes.PhoneAlreadyRegistered):
                message = 'Telefon je već registrovan';
                break;
              case (ErrorCodes.MailAlreadyRegistered):
                message = 'eMail adresa je već registrovana';
                break;
              case (ErrorCodes.InvalidToken): {
                message = 'Sesija istekla. Prijavite se opet';
                this.router.navigateByUrl('/logout');
                break;
              }
              case (ErrorCodes.UserDisabled): {
                message = 'Nalog zaključan';
                this.router.navigateByUrl('/logout');
                break;
              }
              case (ErrorCodes.HasReservations): {
                message = 'Promena nije moguća. Postoje rezervacije van novog vremena';
                break;
              }
              case (ErrorCodes.AlreadyExtended): {
                message = 'Period je već produžen';
                break;
              }
              case (ErrorCodes.TooManyNotifications): {
                message = 'Ne možete dodati još jedno obaveštenje ovog tipa';
                break;
              }
              case (ErrorCodes.InvalidNotification): {
                message = 'Neispravno obaveštenje';
                break;
              }
              case (ErrorCodes.InvalidReservation): {
                message = 'Neispravna rezervacije';
                break;
              } default:
                message = 'Greška';
                break;
            }
            if (message) {
              this.toast.error(message);
            }
          }
        }
      })
    );
  }
}
