export enum ErrorCodes {
  TimeSlotNotFound = 1,
  NotEnoughTime = 2,
  InvalidFreeTimeBetweenReservations = 3,
  InvalidSlot = 4,
  ProductNotFound = 5,
  TimeNotValid = 6,
  TooManySlots = 7,
  AccessDenied = 8,
  InvalidOffice = 9,
  InvalidCompany = 10,
  TooManyOffices = 11,
  TooManyReservations = 12,
  SubscriptionExpired = 13,
  InvalidOperation = 14,
  PhoneNoNotVerified = 15,
  PhoneAlreadyRegistered = 16,
  InvalidToken = 17,
  UserNotFound = 18,
  InvalidResetPasswordToken = 19,
  InvalidPassword = 20,
  InvalidUserNamePassword = 21,
  UserDisabled = 23,
  HasReservations = 24,
  ReservationNotFound = 25,
  CompanyAlreadyRegistered = 26,
  CompanyNotFound = 27,
  AddonNotFound = 28,
  InvalidAddon = 29,
  MailAlreadyRegistered = 30,
  AlreadyExtended = 39,
  InvalidNotification = 40,
  TooManyNotifications = 41,
  InvalidReservation = 43,
  InvalidPhoneNumber = 44,
  InvalidCode = 45
}
