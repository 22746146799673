import { addMinutes, differenceInMinutes } from 'date-fns';
import { CompanyUser } from './User';
import { parseToLocalDate } from 'src/app/core/helpers/functions';

export interface ReservationMetadata   {
  value: string;
  name: string;
}

export class Reservation {
  reservationID = 0;
  officeID: number = 0;
  reservedAt: Date | null = null;
  modifiedAt: Date | null = null;
  productIDs: number[] = [];
  reservedBy: number | null = null;
  readOnly: boolean | null = null;
  note = '';
  status: ReservationStatus = ReservationStatus.Active;
  user: CompanyUser;
  phoneNo = '';
  price = 0;
  metadata: ReservationMetadata[] | null = null;
  isModified = false;
  cancelReason = '';

  private _slotID: number = 0;
  private _start: Date | null = null;
  private _end: Date | null = null;

  // koliko minuta nakon početka rezervacija započinje pauza
  breakStartOffset = 0;
  // trajanje pauze u minutima
  breakDuration = 0;

  public get hasBreak(): boolean {
    return this.breakDuration !== 0;
  }

  public get breakStart(): Date | null {

    if (!this.start) {
      return null;
    }

    const date = addMinutes(this.start, this.breakStartOffset);
    if (date.getTime() > this.end!.getTime()) {
      return this.end;
    }

    return date;
  }

  public get breakEnd(): Date | null {
    if (!this.breakStart) {
      return null;
    }


    const date = addMinutes(this.breakStart, this.breakDuration);
    if (date.getTime() > this.end!.getTime()) {
      return this.end;
    }

    return date;
  }

  public get slotID(): number {
    return this._slotID;
  }
  public set slotID(value: number) {
    if (value !== this._slotID) {
      this.isModified = true;
    }
    this._slotID = value;
  }

  public get start(): Date | null {
    return this._start;
  }
  public set start(value: Date | null) {
    if (!(value instanceof Date)) {
      value = parseToLocalDate(value);
    }

    if (value?.getTime() !== this._start?.getTime()) {
      this.isModified = true;
    }
    this._start = value;
  }

  public get end(): Date | null {
    return this._end;
  }
  public set end(value: Date | null) {
    if (!(value instanceof Date)) {
      value = parseToLocalDate(value);
    }

    if (value?.getTime() !== this._end?.getTime()) {
      this.isModified = true;
    }
    this._end = value;
  }

  get durationInMinutes() {
    if (!this.end || !this.start)
      return 0;

    return differenceInMinutes(this.end, this.start);
  }

  constructor() {
    this.user = new CompanyUser();
  }

  static fromJson(r: Reservation): Reservation {
    const newReservation = new Reservation();
    Object.assign(newReservation, r);

    const user = CompanyUser.fromJSON(r.user);
    newReservation.user = user;

    newReservation.isModified = false;
    newReservation.reservedAt = new Date(r.reservedAt!);
    newReservation.modifiedAt = new Date(r.modifiedAt!);

    return newReservation;
  }

  static fromJsonArray(receovedReservations: Reservation[]): Reservation[] | null {
    if (!receovedReservations) {
      return null;
    }

    const reservations: Reservation[] = [];
    if (receovedReservations) {
      receovedReservations.forEach(receivedReservation => {
        const newSlot = Reservation.fromJson(receivedReservation);
        reservations.push(newSlot);
      });
    }
    return reservations;
  }

  public toJSON(): string {
    const proto = Object.getPrototypeOf(this);
    const jsonObj: any = Object.assign({}, this);

    Object.entries(Object.getOwnPropertyDescriptors(proto))
      .filter(([key, descriptor]) => typeof descriptor.get === 'function')
      .map(([key, descriptor]) => {
        if (descriptor && key[0] !== '_') {
          try {
            let val;

          /*  if ((this as any)[key] instanceof Date) {
              val = toLocalDateJsonString((this as any)[key] as any);
            } else {*/
              val = (this as any)[key];
           /* }*/


            jsonObj[key] = val;
          } catch (error) {
            console.error(`Error calling getter ${key}`, error);
          }
        }
      });

    return jsonObj;

  }

  isEqual(reservation: Reservation): boolean {
    const r1: any = new Reservation();
    Object.assign(r1, this);

    const r2: any = new Reservation();
    Object.assign(r2, reservation);

    r1.modifiedAt = null;
    r2.modifiedAt = null;

    r1.user = null;
    r2.user = null;

    r1.reservedAt = null;
    r2.reservedAt = null;

    return JSON.stringify(r1) === JSON.stringify(r2);
  }
}

export enum ReservationStatus {
  Active = 1,
  Completed = 2,
  Canceled = 3,
  NoShow = 4,
  NeedApproval = 5
}


export interface AvailableReservationDate {
  date: Date;
  slotID: number;
  officeID: number;
  slotName?: string;
  officeName?: string;
}

export interface AvailableReservationDatesForOffice {
  officeID: number;
  name: string;
  slots: Slot[];
}

export interface GetAvailableDatesSimpleModel {
  date: string;
  productIDs: number[];
}
export interface Slot {
  slotID: number;
  name: string;
  dates: Date[];
}

export interface GetAvailableDatesModel {
  officeIDs?: number[] | null;
  start: string;
  end: string;
  reservation: Reservation;
}



export interface CreateTestReservationsModel {
  officeID: number;
  startDate: string;
  endDate: string;
  numberOfReservationsPerDay: number;
}
