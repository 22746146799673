import { AuthServiceService } from './../services/auth-service.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { filter, first } from 'rxjs/operators';

@Injectable()
export class AuthTokenLoadedResolver implements Resolve<any> {

  constructor(private auth: AuthServiceService) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.auth.tokenLoaded.asObservable().pipe(
      filter(val=>val),
      first()
    );
  }
}
