<div class="homeContainer">
  <div class="about">
    <img class="logo" src="./assets/logo.png" alt="" />
    <h1>Dobrodošli u <span class="siteName">eZakaži</span></h1>
    <div class="description">
      <p>
        <span class="highlight">eZakaži</span> je online servis koji unapređuje
        Vaše iskustvo u zakazivanju. Zakazivanje termina za Vaše usluge će biti
        brže, lakše i jednostavnije bez obzira gde se nalazite.
      </p>
      <p>
        Zapisivanje i traženje zakazanih termina po rokovnicima i papirićima
        zamenite najnaprednijom tehnologijom zakazivanja, koja Vam omogućava
        preciznost i kontrolu nad Vašim poslovanjem i to na jednom mestu.
      </p>
      <ul>
        <li>Smanjite broj propuštenih termina!</li>
        <li>Unapredite sistem zakazivanja.</li>
        <li>Povećajte svoj promet.</li>
        <li>
          Steknite pozitivan utisak klijenata i podignite organizaciju na viši
          nivo.
        </li>
      </ul>
      <p>
        <span class="highlight">PRIJAVITE SE</span> i ostvarite kontrolu nad
        svojim poslovanjem!
      </p>
    </div>
  </div>

  <div class="actions">
    <img class="logo d-md-none" src="./assets/logo.png" alt="" />

    <ng-container
      *ngIf="myAuthService.currentUser"
      [ngTemplateOutlet]="loggedInTemplate"
    ></ng-container>

    <ng-container *ngIf="!myAuthService.currentUser" >
      <ng-container
        [ngTemplateOutlet]="logInTemplate"
      ></ng-container>
    </ng-container>
  </div>
  <div class="footer"></div>
</div>

<ng-template #loggedInTemplate>
  <div *ngIf="myAuthService.currentUser" class="loggedInLinks">
    <button class="btn btn-primary" routerLink="/backoffice">BackOffice</button>
    <button
      class="btn btn-secondary"
      routerLink="/admin"
      *ngIf="myAuthService.currentUser.isAdmin"
    >
      admin
    </button>
    <button class="btn btn-outline-secondary" routerLink="/logout">
      Logout
    </button>
  </div>
</ng-template>

<ng-template #logInTemplate>
  <h2>PRIJAVA</h2>
  <app-login> </app-login>
  <div class="">
    <a class="lostPassword" [href]="'https://' + host + '/lostpassword'">
      Zaboravljena šifra?
    </a>
  </div>
  <div class="jumpButtons">
    <a class="btn btn-outline-primary" [href]="'https://' + host + '/register'">
      Registruj se
    </a>
  </div>
</ng-template>
