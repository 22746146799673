<form class="login" *ngIf="!isBusy else loading"
      [formGroup]="loginForm">

  <div class="inputField">
    <div class="label">eMail</div>
    <input autocomplete="username"
           required
           email
           formControlName="username"
           type="email" />
    <span class="error"
          *ngIf="
          loginForm.get('username').touched &&
          loginForm.get('username').hasError('required')
        ">podatak je obavezan</span>
  </div>
  <div class="inputField">
    <div class="label">Šifra</div>
    <input autocomplete="current-password"
           required
           formControlName="password"
           type="password" />
    <span class="error"
          *ngIf="
          loginForm.get('password').touched &&
          loginForm.get('password').hasError('required')
        ">podatak je obavezan</span>
  </div>
  <button class="submit dropShadowPrimary"
          (click)="login()">
    ULOGUJ SE
  </button>
  <div class="socialLoginHeader">
    <div class="line"></div>
    <span>Uloguj se preko</span>
  </div>
  <div class="d-flex justify-content-center">
    <button class="socialLoginButton"
            (click)="signInWithGoogle()">
      <img src="./assets/google-icon.png"
           alt="" />
    </button>
    <button class="socialLoginButton"
            (click)="signInWithFB()">
      <img src="./assets/FB-icon.png"
           alt="" />
    </button>
  </div>

</form>

<ng-template #loading>

  <div class="loading  ">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</ng-template>
