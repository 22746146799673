import { environment } from 'src/environments/environment';
import { Component } from '@angular/core';

import { AuthServiceService as myAuth } from '../core/services/auth-service.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  viewDate: Date = new Date();
  isBusy = false;
  lostPasswordForm: FormGroup;
  resetPasswordToken;
  host = environment.host;

  constructor(
    public myAuthService: myAuth) {

  }

}
