import { ToastrService } from 'ngx-toastr';
import { WebSocketService } from './web-socket.service';
import { ErrorCodes } from '../interfaces/ErrorCodes';
import { GenericItemApiResponse } from './../interfaces/ApiResponse';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User, LoginResult } from '../interfaces/User';
import { mapJsonToUsers } from '../helpers/functions';

import { CrossDomainStorageService } from './cross-domain-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  userChanged = new BehaviorSubject<User | null>(null);
  impersonateToken?: string;
  tokenLoaded = new BehaviorSubject(false);

  token: string = '';

  get isLoggedOn(){
    return !!this.currentUser;
  }

  constructor(private http: HttpClient,
    private jwt: JwtHelperService,
    private webSocket: WebSocketService,
    toast: ToastrService,
    private crossDomanStorage: CrossDomainStorageService) {

    crossDomanStorage.get('authToken').then(token => {
      this.token = token || '';

      this.setCurrentUser(token);
      this.tokenLoaded.next(true);
      setTimeout(() => {
        this.refreshToken();
      }, 20);
    });

    webSocket.connected.subscribe(
      connected => {
        if (connected) {
          this.sendWsAuth();
        }
      }
    );

    webSocket.observe('auth').subscribe(
      data => {
        if (data === 'invalid') {
          toast.error('Sesija istekla. Prijavite se opet');
        }
      }
    );
  }


  saveToken(token: string) {
    this.crossDomanStorage.set('authToken', token);

    this.token = token;
  }

  get isImperosnating() {
    return (!!this.impersonateToken);
  }

  impersonateUser(token?: string) {
    this.impersonateToken = token;
  }

  getToken() {
    return this.impersonateToken || this.token;
  }

  sendWsAuth() {
    if (!this.token) {
      return;
    }

    const post = {
      type: 'auth',
      data: this.getToken()
    };

    this.webSocket.send(post);
  }

  get currentUser(): User | null {
    return this.userChanged.value;
  }

  setCurrentUser(token: string) {
    if (this.jwt.isTokenExpired(token)) {
      return;
    }

    const decoded = this.jwt.decodeToken(token);
    if (decoded == null) {
      return;
    }


    try {
      const jData = JSON.parse(decoded.user);
      const user = mapJsonToUsers([jData])[0];

      this.crossDomanStorage.set('authToken', token);

      this.token = token;

      this.sendWsAuth();

      this.userChanged.next(user);

    } catch (error) {

    }

  }

  signOut() {
    this.crossDomanStorage.remove('authToken');
    this.token = '';
    this.userChanged.next(null);
  }


  refreshToken() {
    const apiAddress = environment.webApiBase + 'user/RefreshToken';

    this.http.get<GenericItemApiResponse<string>>(apiAddress).subscribe(
      result => {
        if (result.success) {
          this.saveToken(result.item);
          this.setCurrentUser(result.item);
        }
      }
    );
  }

  googleSignInExternal(googleTokenId: string): Observable<LoginResult> {
    const apiAddress = environment.webApiBase + 'user/googlesigninexternal';

    return this.http.get<GenericItemApiResponse<string>>(apiAddress, {
      params: new HttpParams().set('googleTokenId', googleTokenId),
      headers: { 'SkipApiResponseInterceptor': '' }
    })
      .pipe(
        map((result) => {
          if (result.success) {
            this.saveToken(result.item);
            this.setCurrentUser(result.item);
            return LoginResult.OK;
          }
          if (result.errorCode === ErrorCodes.InvalidUserNamePassword) {
            return LoginResult.InvalidUserName;
          }

          return LoginResult.Disabled;
        })
      );

  }

  facebookSignInExternal(fbTokenId: string): Observable<LoginResult> {
    const apiAddress = environment.webApiBase + 'user/facebooksigninexternal';

    return this.http.get<GenericItemApiResponse<string>>(apiAddress, {
      params: new HttpParams().set('fbTokenId', fbTokenId),
      headers: { 'SkipApiResponseInterceptor': '' }
    })
      .pipe(
        map((result) => {
          if (result.success) {
            this.saveToken(result.item);
            this.setCurrentUser(result.item);
            return LoginResult.OK;
          }
          if (result.errorCode === ErrorCodes.InvalidUserNamePassword) {
            return LoginResult.InvalidUserName;
          }

          return LoginResult.Disabled;

        })
      );

  }


  login(data: { username: string, password: string }): Observable<LoginResult> {
    const apiAddress = environment.webApiBase + `user/login?username=${encodeURIComponent(data.username)}&password=${encodeURIComponent(data.password)}`;

    const result = this.http.get<GenericItemApiResponse<string>>(apiAddress, {
      headers: { 'SkipApiResponseInterceptor': '' }
    }).pipe(
      map(response => {
        if (response.success) {
          this.saveToken(response.item);
          this.setCurrentUser(response.item);
          return LoginResult.OK;
        }

        if (response.errorCode === ErrorCodes.InvalidUserNamePassword) {
          return LoginResult.InvalidUserName;
        }

        return LoginResult.Disabled;
      })
    );

    return result;
  }


}
