import { WeekDay } from '@angular/common';

var host = 'test.ezakazi.rs';

export const environment = {
  production: true,
  webApiBase: 'https://' + host + '/api/',
  webBase: 'https://' + host,
  host: host,
  ga: 'G-MR1BFQTBL9',
  title: 'eZakaži - TEST',
  weekStartsOn: WeekDay.Monday,
  googleClientID: '997262538817-bqvoogk9gd2tho6032bhrulljhtjqao4.apps.googleusercontent.com'

};
