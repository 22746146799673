import { Component } from '@angular/core';

import { registerLocaleData } from '@angular/common';
import localeSr from '@angular/common/locales/sr-Latn';
import { AuthServiceService } from './core/services/auth-service.service';
import { environment } from 'src/environments/environment';

import * as  LogRocket from 'logrocket';

registerLocaleData(localeSr);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  constructor(auth: AuthServiceService) {

    if (environment.production) {
      auth.userChanged.subscribe(
        user => {
          if (user) {
            LogRocket.identify(user.userID.toString(), {
              name: user.name
            });
          }
        }
      )

    }
  }
}
